@font-face {
  font-family: "NanumSquareNeo-Variable";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_11-01@1.0/NanumSquareNeo-Variable.woff2")
    format("woff2");
  font-weight: normal;
  font-style: normal;
}

body {
  width: 100vw;
  overflow-x: hidden;
  margin: 0 auto;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Set the width and height of the scrollbar */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Set the background color of the scrollbar */
.shop-list-page div ::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

/* Set the color of the scrollbar thumb */
::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}

/* Keep the scrollbar always visible */
::-webkit-scrollbar-thumb:vertical {
  min-height: 50px;
}

.App {
  width: 100vw;
  height: 100vh;
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.header a,
.news-table a {
  text-decoration: none !important;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.MuiTypography-h1 {
  color: #fff;
}

.news-page,
.news-detail-page,
.shop-list-page,
.company-page {
  width: 100vw;
  /* height: 100vh; */
  overflow: hidden;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}

.news-page p,
.news-title p,
.news-detail-page p,
.product-page p,
.company-page p {
  font-family: "eb-garamond", serif;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0.04em;
}

.news-title hr {
  border-color: #000;
}

.news-page button,
.news-detail-page button {
  color: #000 !important;
}

.product-title hr {
  /* width: calc(95vw - 200px); */
  border-color: #000;
}

.slick-dots {
  bottom: 25px !important;
}

.slick-dots li.slick-active button:before {
  /* color: #fff !important; */
}

#menu-appbar {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  /* border-radius: 0 20px 20px 0; */
}

.MuiMenu-paper {
  box-shadow: none !important;
}

.appbar {
  width: 93vw;
  height: 120px;
}

.roading p {
  font-family: "EB Garamond", serif;
}

.header p,
.header button,
.footer p {
  font-family: "EB Garamond", serif;
  text-transform: none;
}

.header button {
  font-weight: 600;
}

.header button:hover {
  background-color: #d0d0d00a;
}

.icon-button {
  justify-content: flex-start !important;
}

.MuiTabs-flexContainer {
  justify-content: space-between;
}
